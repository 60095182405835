import React from "react";
import loc1 from "../../assets/imgs/location/1.jpg";
import loc2 from "../../assets/imgs/location/2.jpg";
import loc3 from "../../assets/imgs/location/3.jpg";
import loc4 from "../../assets/imgs/location/4.jpg";
import loc5 from "../../assets/imgs/location/5.jpg";
import loc6 from "../../assets/imgs/location/6.jpg";
import loc7 from "../../assets/imgs/location/7.jpg";
import loc8 from "../../assets/imgs/location/8.jpg";
import loc9 from "../../assets/imgs/location/9.jpg";
import loc10 from "../../assets/imgs/location/10.jpg";
import loc11 from "../../assets/imgs/location/11.jpg";
import loc12 from "../../assets/imgs/location/12.jpg";
import { IconLocation } from "../../assets/icons/icons";
import "../../styles/views/location.css";
import { linkLocal, local } from "../../providers/infos";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

function Location() {
  return (
    <div className="clinit-start--location">
      <div className="clinit-start--location-degrade-top" />
      <h1 className="clinit-start--title">
        <b className="clinit-start--about__area-text-b">Nossa Estrutura</b> está
        a sua disposição
      </h1>

      <div
        style={{ top: 120 }}
        className="clinit-start--details-img-left"
      ></div>

      <section className="clinit-start--location_content">
        <div className="clinit-start--location_content--imgs clinit-start--location_content--imgs-left clinit-start--partners-slider">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
          >
            {[
              loc2,
              loc11,
              loc8,
              loc3,
              loc5,
              loc10,
              loc1,
              loc6,
              loc7,
              loc4,
              loc9,
              loc12,
            ]?.map((img, i) => (
              <SwiperSlide className="clinit-start--location_content-image-slider-slide">
                <img src={img} loading="async" alt={`estrutura img ${i}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="clinit-start--location_content--text">
          <p>
            A <b className="font-ideal">IDEAL SST</b> possui uma estrutura
            confortável e moderna, proporcionando agilidade, conforto e
            eficiência no atendimento aos nossos clientes.
          </p>

          <div>
            <IconLocation onClick={() => window.open(linkLocal)} />
            <p onClick={() => window.open(linkLocal)}>{local}</p>
          </div>
        </div>

        <div className="clinit-start--location_content--imgs clinit-start--location_content--imgs-right clinit-start--partners-slider">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            loop={true}
            autoplay={{
              delay: 500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
          >
            {[
              loc7,
              loc10,
              loc1,
              loc3,
              loc4,
              loc8,
              loc11,
              loc6,
              loc2,
              loc9,
              loc12,
              loc5,
            ]?.map((img, i) => (
              <SwiperSlide className="clinit-start--location_content-image-slider-slide">
                <img src={img} alt={`estrutura img ${i}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <div
        style={{ bottom: 100 }}
        className="clinit-start--details-img-right"
      ></div>
    </div>
  );
}

export default Location;
