import React, { useMemo, useRef } from "react";
import "../../styles/views/partners.css";

import logo1 from "../../assets/imgs/partners/aco50.jpeg";
import logo2 from "../../assets/imgs/partners/brisanet.jpg";
import logo3 from "../../assets/imgs/partners/centerbox.png";
import logo4 from "../../assets/imgs/partners/certa.jpeg";
import logo5 from "../../assets/imgs/partners/cia logo.jpg";
import logo6 from "../../assets/imgs/partners/cometa.jpg";
import logo7 from "../../assets/imgs/partners/espaço manix.webp";
import logo8 from "../../assets/imgs/partners/immu.jpeg";
import logo9 from "../../assets/imgs/partners/paulista.png";
import logo10 from "../../assets/imgs/partners/sindmoto.png";
import logo11 from "../../assets/imgs/partners/sintro.png";
import logo12 from "../../assets/imgs/partners/teleentrega.png";
import logo13 from "../../assets/imgs/partners/teleentulho.png";
import logo14 from "../../assets/imgs/partners/ultrapopular.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

function Partners() {
  const partners = useMemo(
    () => [
      {
        id: 1,
        logo: logo1,
        title: "Aço 50",
      },
      {
        id: 2,
        logo: logo2,
        title: "Brisa Net",
      },
      {
        id: 3,
        logo: logo3,
        title: "Center Box",
      },
      {
        id: 4,
        logo: logo4,
        title: "Certa",
      },
      {
        id: 5,
        logo: logo5,
        title: "Cia",
      },
      {
        id: 6,
        logo: logo6,
        title: "Cometa",
      },
      {
        id: 7,
        logo: logo7,
        title: "Espaço Manix",
      },
      {
        id: 8,
        logo: logo8,
        title: "Immu",
      },
      {
        id: 9,
        logo: logo9,
        title: "Paulista",
      },
      {
        id: 10,
        logo: logo10,
        title: "Sind Moto",
      },
      {
        id: 11,
        logo: logo11,
        title: "Sintro",
      },
      {
        id: 12,
        logo: logo12,
        title: "Tele Entrega",
      },
      {
        id: 13,
        logo: logo13,
        title: "Tele Entulho",
      },
      {
        id: 14,
        logo: logo14,
        title: "Ultra Popular",
      },
    ],
    []
  );
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <div className="clinit-start--partners">
      <h1 className="clinit-start--title">
        Nossos <b className="clinit-start--about__area-text-b">Clientes</b>
      </h1>
      {/* <div className="clinit-start--partners-slider">
        <div className="clinit-start--partners-slide-track">
          {partners?.map((patner) => (
            <div className="clinit-start--partners-slide">
              <img
                src={patner?.logo}
                alt={patner?.title}
                id={"patner-" + patner?.id}
              />
            </div>
          ))}
        </div>
      </div> */}
      <div className="clinit-start--partners-slider">
        <Swiper
          slidesPerView={width <= 500 ? 3 : 7}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
        >
          {partners?.map((patner) => (
            <SwiperSlide className="clinit-start--partners-slide">
              <img
                src={patner?.logo}
                alt={patner?.title}
                id={"patner-" + patner?.id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Partners;
