import React from "react";
import logoAllWhite from "../../assets/imgs/logo/HORIZONTAL_BRANCO.png";
import {
  IconCalendar,
  IconFacebook,
  IconInstagram,
  IconLocationNormal,
  IconTwitter,
} from "../../assets/icons/icons";
import { Link } from "react-scroll";
import "../../styles/layouts/footer.css";
import {
  email,
  linkInstagram,
  linkLocal,
  linkWhatsapp,
  local,
  nav,
  telefone,
} from "../../providers/infos";
import { useNavigate } from "react-router-dom";

function Footer({ setActiveLink, activeLink }) {
  const history = useNavigate();

  return (
    <div id="contacts" className="clinit-start--back-bottom">
      <div className="clinit-start--contacts">
        <section>
          <div
            onClick={() => window.open(linkWhatsapp)}
            className="clinit-start--contacts-item"
          >
            <IconTelephone />
            <div>
              <p>Telefone Fixo e WhatsApp</p>
              <h5>{telefone}</h5>
            </div>
          </div>
          <div className="clinit-start--contacts-item">
            <IconCalendar className={"icon-calendar-footer"} />
            <div>
              <p>Estamos abertos de</p>
              <h5>Segunda-Sexta, 7:00 - 16:00</h5>
            </div>
          </div>
          <div
            onClick={() => window.open(`mailto:${email}`)}
            className="clinit-start--contacts-item"
          >
            <IconEmail />
            <div>
              <p>Envie-nos um e-mail</p>
              <h5>{email}</h5>
            </div>
          </div>
        </section>
      </div>
      <div className="clinit-start--footer">
        <section className="clinit-start--footer-content">
          <div className="clinit-start--footer-content-left">
            <img
              src={logoAllWhite}
              onClick={() => {
                history(`/`);
                window.scrollTo({ top: 0 });
              }}
              style={{ cursor: "pointer" }}
              alt="logo Start"
            />
            <div>
              <IconLocationNormal onClick={() => window.open(linkLocal)} />
              <p onClick={() => window.open(linkLocal)}>{local}</p>
            </div>
            <section>
              {/* <IconFacebook onClick={() => window.open(``)} /> */}
              <IconInstagram
                className="instagram-icon-footer"
                onClick={() => window.open(linkInstagram)}
              />
              <a target="_blank" without rel="noreferrer" href={linkInstagram}>
                Siga-nos no nosso Instagram!
              </a>
              {/* <IconTwitter onClick={() => window.open("")} /> */}
            </section>
          </div>
          <div className="clinit-start--footer-content-links">
            <h3>Links Úteis</h3>
            {nav?.map((link) => (
              <Link
                id={link?.id}
                to={link?.to}
                className={
                  activeLink === link?.id
                    ? "clinit-start--footer-content-links-active"
                    : ""
                }
                onClick={() => {
                  setActiveLink(link?.id);
                  history(`/${link?.href ? link?.href : ""}`);
                  window.scrollTo({ top: 0 });
                }}
                spy={true}
                smooth={true}
                offset={0}
                delay={0}
                duration={120}
              >
                {link?.name}

                {activeLink === link?.id ? <section></section> : ""}
              </Link>
            ))}
          </div>
        </section>
        <section className="clinit-start--footer-bottom">
          {/* <div
            style={{ bottom: 15 }}
            className="clinit-start--details-img-left"
          ></div> */}
          <section />
          <p>
            © 2023{" "}
            <b className="font-ideal" style={{ color: "#fff", fontSize: 18 }}>
              IDEAL SST
            </b>{" "}
            LTDA. All Rights Reserved.
          </p>
          <section />
          {/* <div
            style={{ bottom: 15 }}
            className="clinit-start--details-img-right"
          ></div> */}
        </section>
      </div>
    </div>
  );
}

export default Footer;

const IconTelephone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4.74038 14.3685L6.69351 12.9816C7.24445 12.5904 7.80305 12.3282 8.44034 12.1585C9.17201 11.9636 9.5 11.5644 9.5 10.711C9.5 8.54628 14.5 8.31594 14.5 10.711C14.5 11.5644 14.828 11.9636 15.5597 12.1585C16.202 12.3295 16.7599 12.5934 17.3065 12.9816L19.2596 14.3685C20.1434 14.9961 20.5547 15.2995 20.7842 15.7819C21 16.2358 21 16.768 21 17.8324C21 19.7461 21 20.703 20.4642 21.3164C19.8152 22.0593 18.128 21.9955 17.0917 21.9955H6.90833C5.87197 21.9955 4.21909 22.0986 3.5358 21.3164C3 20.703 3 19.7461 3 17.8324C3 16.768 3 16.2358 3.21584 15.7819C3.44526 15.2995 3.85662 14.9961 4.74038 14.3685Z"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M14 17C14 18.1046 13.1046 19 12 19C10.8954 19 10 18.1046 10 17C10 15.8954 10.8954 15 12 15C13.1046 15 14 15.8954 14 17Z"
      stroke="#141B34"
      stroke-width="1.5"
    />
    <path
      d="M6.96014 3.69772C5.6417 4.07415 4.69384 4.54112 3.82645 5.10455C2.45318 5.9966 1.86443 7.60404 2.02607 9.15513C2.09439 9.81068 2.62064 10.1241 3.23089 9.95455C3.69451 9.82571 4.15888 9.7003 4.61961 9.56364C5.96706 9.16397 6.28399 8.67812 6.47124 7.29885L6.96014 3.69772ZM6.96014 3.69772C10.2186 2.76743 13.7814 2.76743 17.0399 3.69772M17.0399 3.69772C18.3583 4.07415 19.3062 4.54112 20.1735 5.10455C21.5468 5.9966 22.1356 7.60404 21.9739 9.15513C21.9056 9.81068 21.3794 10.1241 20.7691 9.95455C20.3055 9.82571 19.8411 9.7003 19.3804 9.56364C18.0329 9.16397 17.716 8.67812 17.5288 7.29885L17.0399 3.69772Z"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
);

const IconEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2 5L8.91302 8.92462C11.4387 10.3585 12.5613 10.3585 15.087 8.92462L22 5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 19.5C10.0337 19.4939 9.56682 19.485 9.09883 19.4732C5.95033 19.3941 4.37608 19.3545 3.24496 18.2184C2.11383 17.0823 2.08114 15.5487 2.01577 12.4814C1.99475 11.4951 1.99474 10.5147 2.01576 9.52843C2.08114 6.46113 2.11382 4.92748 3.24495 3.79139C4.37608 2.6553 5.95033 2.61573 9.09882 2.53658C11.0393 2.4878 12.9607 2.48781 14.9012 2.53659C18.0497 2.61574 19.6239 2.65532 20.755 3.79141C21.8862 4.92749 21.9189 6.46114 21.9842 9.52844C21.9939 9.98251 21.9991 10.1965 21.9999 10.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 17C19 17.8284 18.3284 18.5 17.5 18.5C16.6716 18.5 16 17.8284 16 17C16 16.1716 16.6716 15.5 17.5 15.5C18.3284 15.5 19 16.1716 19 17ZM19 17V17.5C19 18.3284 19.6716 19 20.5 19C21.3284 19 22 18.3284 22 17.5V17C22 14.5147 19.9853 12.5 17.5 12.5C15.0147 12.5 13 14.5147 13 17C13 19.4853 15.0147 21.5 17.5 21.5"
      stroke="#141B34"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
