import React, { useMemo, useRef, useState } from "react";
import { IconArrowRight } from "../../assets/icons/icons";
import useDraggableScroll from 'use-draggable-scroll';
import {linkWhatsapp} from '../../providers/infos'

function Services({ services, activeService, setActiveService }) {
  const servicesActive = useMemo(() => {
    const filter = services?.filter((serv) => {
      return serv?.id === activeService;
    });
    return filter[0]?.itens;
  }, [activeService, services]);
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref, {
    direction: "horizontal",
  });
  return (
    <div id="services" className="clinit-start--services">
      <h1 className="clinit-start--title">
        Nossos <b className="clinit-start--about__area-text-b">Serviços</b>
      </h1>

      <div className="clinit-start--services-content">
        <div className="clinit-start--services-content__back-transparent-left" />
        <section className="clinit-start--services-content__filter">
          {services?.map((serv) => (
            <button
              className={
                activeService === serv?.id
                  ? "clinit-start--services-content__filter-btn clinit-start--services-content__filter-btn-active"
                  : "clinit-start--services-content__filter-btn"
              }
              onClick={() => setActiveService(serv?.id)}
            >
              {serv?.icon}
              <p>{serv?.type}</p>
            </button>
          ))}
        </section>

        <section
          ref={ref}
          onMouseDown={onMouseDown}
          className="clinit-start--services-content__itens"
        >
          {servicesActive?.map((serv) =>
            serv?.type === "SESMT" ? (
              <div className="clinit-start--services-content__itens--item_sesmt">
                <h1>{serv?.title}</h1>
                <p>{serv?.desc?.replaceAll("•", "\n•")}</p>
              </div>
            ) : (
              <div className="clinit-start--services-content__itens--item">
                <h1>{serv?.title}</h1>
                <section />
                <p>{serv?.desc}</p>
                <button onClick={() => window.open(linkWhatsapp)} className="clinit-start--btn--second clinit-start--btn clinit-start--banner__area-text-hours-btn">
                  <IconArrowRight className="clinit-start--banner__area-text-hours-icon" />{" "}
                  Agendamento
                </button>
              </div>
            )
          )}
        </section>

        <div className="clinit-start--services-content__back-transparent" />
      </div>
      {/* 
      <div
          style={{ top: 255 }}
          className="clinit-start--details-img-right clinit-start--details-img-x"
        ></div> */}
    </div>
  );
}

export default Services;

const Draggable = ({ innerRef, rootClass = "", children }) => {
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const [isScrolling, setIsScrolling] = useState(false);
  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    console.log(walkX, walkY);
  };

  return (
    <div
      ref={ourRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      className={rootClass}
    >
      {children}
    </div>
  );
};
