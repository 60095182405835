const linkWhatsapp = "https://bit.ly/atendimento-idealsst";
const linkLocal = "https://goo.gl/maps/SJ6nEXTmiJoibpU96";
const local = "Rua Padre Mororó 2113 Farias Brito - 60015-221 | Fortaleza CE";
const telefone = "85 3393-1976";
const email = "atendimento@idealsst.com.br";
const linkInstagram = "https://www.instagram.com/clinicaidealsst/";
const nav = [
  {
    id: 1,
    name: "Início",
    to: "banner",
  },
  {
    id: 2,
    name: "Quem somos",
    to: "about",
  },
  {
    id: 3,
    name: "Serviços",
    to: "services",
  },
  {
    id: 4,
    name: "Contatos",
    to: "contacts",
  },
  {
    id: 5,
    name: "Portifólio",
    to: "portfolio",
    // href: "portifolio",
  },
];

export { nav, linkWhatsapp, linkLocal, local, telefone, email, linkInstagram };
