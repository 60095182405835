/*

        
      ↪ APP CLINIC SST


*/

import React, { useEffect, useState } from "react";
import Banner from "./views/banner";
import AOS from "aos";
import "aos/dist/aos.css";
import PreviewServices from "./views/services/preview";
import About from "./views/about";
import Location from "./views/location";
import SESMT from "./views/sesmt";
import Services from "./views/services";
import {
  IconArrowUp,
  IconMediTraba,
  IconSegTraba,
  IconTerSESMT,
  IconWhatsApp,
} from "./assets/icons/icons";

import { useWindowScrollPositions } from "./providers/useWindowScrollPositions";
import Rating from "./views/rating";
import Partners from "./views/partners";
import Map from "./views/map";
import Footer from "./layouts/Footer";
import { linkWhatsapp } from "./providers/infos";
import Portifolio from "./views/portifolio";
import { BrowserRouter, Route, Routes } from "react-router-dom";

AOS.init();

function App() {
  const [activeLink, setActiveLink] = useState(1);

  const [top, setTop] = useState(false);
  const { scrollY } = useWindowScrollPositions();

  const [activeService, setActiveService] = useState(1);
  const [services, setServices] = useState([
    {
      id: 1,
      type: "Medicina do Trabalho",
      icon: <IconMediTraba />,
      desc: "PCMSO • PCA • Exame Clínico (ASO) • Raio-X • Exames Laboratoriais• Exames Complementares",
      itens: [
        {
          title: "Raio-X",
          desc: "Avaliação radiológica para detectar possíveis problemas ósseos ou respiratórios.\n \n PA, Tórax, lombar, partes moles, etc. São mais de 50 tipos diferentes.",
        },
        {
          title: "ASO",
          desc: "Atestado De Saúde Ocupacional: Documento essencial para avaliar a aptidão do colaborador às atividades ocupacionais.",
        },
        {
          title: "Audiometria",
          desc: "Exame para avaliar a audição dos trabalhadores, garantindo a prevenção e proteção contra danos auditivos.",
        },
        {
          title: "Acuidade Visual",
          desc: "Avaliação da capacidade visual dos colaboradores, garantindo um ambiente de trabalho seguro.",
        },
        {
          title: "Espirometria",
          desc: "Exame que avalia a função pulmonar, importante para detectar doenças respiratórias relacionadas ao trabalho.",
        },
        {
          title: "ECG - Eletrocardiograma",
          desc: "Avaliação da saúde cardiovascular dos colaboradores, identificando possíveis condições cardíacas.",
        },
        {
          title: "EEG - Eletroencefalograma",
          desc: "Exame para avaliação das atividades cerebrais, auxiliando na detecção de alterações neurológicas.",
        },
        {
          title: "Toxicológico",
          desc: "Realizado para identificar o uso de substâncias químicas e garantir a segurança no ambiente de trabalho.",
        },
        {
          title: "Exames Laboratoriais",
          desc: "Ampla variedade de exames para monitorar a saúde dos colaboradores e prevenir doenças ocupacionais.",
        },
      ],
    },
    {
      id: 2,
      type: "Segurança do Trabalho",
      icon: <IconSegTraba />,
      desc: "PPRA • PGR • LTCAT • Treinamentos • Avaliações Ambientais",
      itens: [
        {
          title: "Programas (PGR, PCMSO, PPP, LTCAT, PCA)",
          desc: "Programas e documentos essenciais para garantir a segurança, saúde e bem-estar dos trabalhadores.",
        },
        {
          title: "Treinamentos (CIPA, NR 10/11/12/18/20/35)",
          desc: "Capacitação dos colaboradores para a prevenção de acidentes e a correta aplicação das normas regulamentadoras.",
        },
      ],
    },
    {
      id: 3,
      type: "Terceirização de SESMT",
      icon: <IconTerSESMT />,
      desc: "Disponibilizamos os profissionais e fazemos a gestão do ambulatório médico da sua empresa",
      itens: [
        {
          title: "Envio das informações direto para o eSocial",
          desc: "Agilidade e conformidade na transmissão das informações de segurança e saúde ocupacional para o sistema eSocial, facilitando o cumprimento das obrigações legais.",
        },
        {
          type: "SESMT",
          title:
            "Confira abaixo algumas das vantagens da terceirização do SESMT da sua empresa.",
          desc: "Terceirização de mão-de-obra médica, enfermagem, assistente; • Gestão de absenteísmo; • Gestão de afastamento – FAP – NTEP; • Gestão ambulatorial; • Fornecimento e controle de insumos ambulatoriais; • Contestação de decisões do INSS; • Elaboração de relatórios gerenciais sobre absenteísmo por setor, afastamentos, quantidade de atendimentos mensais, dentre outros; • Controle e convocação para realização dos exames periódicos; • Implantação do Software de Gestão Ocupacional; • Palestras preventivas e programas de qualidade de vida.",
        },
      ],
    },
  ]);

  useEffect(() => {
    if (scrollY > 300) {
      setTop(true);
    } else {
      setTop(false);
    }
  }, [scrollY]);

  return (
    <div className="clinit-start--app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <article></article>

                <section className="clinit-start--banner__back"></section>

                <Banner activeLink={activeLink} setActiveLink={setActiveLink} />
                <PreviewServices
                  services={services}
                  setActiveService={setActiveService}
                />
                <About activeLink={activeLink} setActiveLink={setActiveLink} />

                <Location />
                <SESMT />
                <Services
                  services={services}
                  activeService={activeService}
                  setActiveService={setActiveService}
                />
                {/* <Rating /> */}
                <Partners />

                <Map />
              </>
            }
          />
          {/* <Route
            path="/portifolio"
            element={
              <Portifolio
                activeLink={activeLink}
                setActiveLink={setActiveLink}
              />
            }
          /> */}
        </Routes>
        <Footer setActiveLink={setActiveLink} activeLink={activeLink} />

        {/* BTN WHATSAPP */}
        <div
          onClick={() => window.open(linkWhatsapp)}
          style={{ bottom: 15 }}
          className="clinit-start--app--button_top clinit-start--app--button_top-whatsapp"
        >
          <p>
            Contate-nos{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="6"
              viewBox="0 0 22 6"
              fill="none"
            >
              <path
                d="M8.99973 1.1547C10.2373 0.440169 11.7621 0.440169 12.9997 1.1547L21.392 6H0.607422L8.99973 1.1547Z"
                fill="#fff"
                fill-opacity="1"
              />
            </svg>
          </p>
          <IconWhatsApp />
        </div>
        {/* BTN SCROLL TOP */}
        <div
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
          style={{ bottom: top ? 75 : -200 }}
          className="clinit-start--app--button_top"
        >
          <IconArrowUp />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
