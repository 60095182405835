import React from "react";
import '../../styles/views/map.css'

function Map() {
  return (
    <div className="clinit-ideal-sst-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3981.3439782171044!2d-38.5403941!3d-3.7350031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c749088d6b53db%3A0xabd0b3911fca1e7c!2sIDEAL%20SST%20-%20Sa%C3%BAde%20e%20Seguran%C3%A7a%20do%20Trabalho!5e0!3m2!1spt-BR!2sbr!4v1694027657326!5m2!1spt-BR!2sbr"
        width="600"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
