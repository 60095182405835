import React, { useEffect, useState } from "react";
import "../../styles/views/reviews.css";
import { IconQuoteUp, IconStar } from "../../assets/icons/icons";

function Rating() {
  const [reviews, setReviews] = useState({
    html_attributions: [],
    result: {
      rating: 4.9,
      reviews: [
        {
          author_name: "Isa BP",
          profile_photo_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU",
          rating: 5,
          text: "Só elogios. Fui pela manhã fazer a consulta no médico do trabalho. Dr. Fábio Cordeiro me atendeu e se mostrou muito humano e atencioso. A atendente Solange também super atenciosa. Toda vez que precisar, só pretendo ir lá. O tempo de espera foi bem curto.",
        },
        {
          author_name: "Ismael Nascimento",
          profile_photo_url:
            "https://lh3.googleusercontent.com/ogw/AGvuzYZ3O1C-Clz23qxhmMPEorYiju6A2VfB2AjMlwujKg=s32-c-mo",
          rating: 5,
          text: "Muito ótima as atendentes, são muito profissionais, simpática,adorei a técnica Karine👍",
        },
        {
          author_name: "Angelica silva Almeida",
          profile_photo_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU",
          rating: 5,
          text: "Foi maravilhoso bom atendimento Solange Karine e Ana Carla foram maravilhosas local agradável e muito limpo gostei 😃 muito satisfeita.",
        },
        {
          author_name: "Angelica silva Almeida",
          profile_photo_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU",
          rating: 5,
          text: "Foi maravilhoso bom atendimento Solange Karine e Ana Carla foram maravilhosas local agradável e muito limpo gostei 😃 muito satisfeita.",
        },
        {
          author_name: "Angelica silva Almeida",
          profile_photo_url:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYmlp9JDeNMaFZzw9S3G1dVztGqF_2vq9nA&usqp=CAU",
          rating: 5,
          text: "Foi maravilhoso bom atendimento Solange Karine e Ana Carla foram maravilhosas local agradável e muito limpo gostei 😃 muito satisfeita.",
        },
        {
          author_name: "Ismael Nascimento",
          profile_photo_url:
            "https://lh3.googleusercontent.com/ogw/AGvuzYZ3O1C-Clz23qxhmMPEorYiju6A2VfB2AjMlwujKg=s32-c-mo",
          rating: 5,
          text: "Muito ótima as atendentes, são muito profissionais, simpática,adorei a técnica Karine👍",
        },
      ],
    },
  });

  // useEffect(() => {
  //   const placeId = "";
  //   const apiKey = "";

  //   const dataFetch = async () => {
  //     const data = await (
  //       await fetch(
  //         `https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJOYyj0KxOxwcRwhGPs5z9m-o&key=AIzaSyCStUgezmhft1_vK-yxylVT-bnOl5MVmLM`
  //       )
  //     ).json();
  //     setReviews(data);
  //   };
  //   dataFetch();
  // }, []);

  return (
    <div className="clinit-start--rating">
      <h1>
        <IconQuoteUp />O que andam falando{" "}
        <b className="clinit-start--about__area-text-b">sobre nós</b>
      </h1>

      <div className="clinit-start--rating-reviews">
        {reviews?.result?.reviews?.map((review) => (
          <div className="clinit-start--rating-item">
            <section className="clinit-start--rating-item-text">
              <p>{review?.text}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="6"
                viewBox="0 0 22 6"
                fill="none"
              >
                <path
                  d="M8.99973 1.1547C10.2373 0.440169 11.7621 0.440169 12.9997 1.1547L21.392 6H0.607422L8.99973 1.1547Z"
                  fill="#fff"
                  fill-opacity="1"
                />
              </svg>

              <div className="clinit-start--rating-item-stars">
                {[...Array(review?.rating || 5)].map((star) => {
                  return (
                    <div>
                      <IconStar />
                    </div>
                  );
                })}
              </div>
            </section>

            <div className="clinit-start--rating-item-user">
              <img src={review?.profile_photo_url} alt="author url img" />
              <p>{review?.author_name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Rating;
