import React from "react";
import Header from "../../layouts/Header";
import {
  IconArrowRight,
  IconCalendar,
  IconClock,
  IconLocation,
} from "../../assets/icons/icons";
import imageWork from "../../assets/imgs/work.png";
import "../../styles/views/banner.css";
import { linkLocal, local, linkWhatsapp } from "../../providers/infos";

function Banner({ activeLink, setActiveLink }) {
  return (
    <div className="clinit-start--banner" id="banner">
      <Header activeLink={activeLink} setActiveLink={setActiveLink} />

      <div className="clinit-start--banner-area-content">
        <div className="clinit-start--banner__area-text">
          <h2>
            A Resposta <b className="font-ideal">IDEAL</b> em Saúde e Seguraça
            do Trabalho
          </h2>
          <p>
            Priorize a saúde de sua equipe e eleve a performance de sua empresa
            com a <b className="font-ideal">IDEAL SST</b>.
          </p>

          <div className="clinit-start--banner__area-text-hours">
            <p>Horários de funcionamento</p>

            <div>
              <article>
                <section>
                  <IconCalendar className={"icon-clock-banner"} />
                  <p>Seg. à Sex.</p>
                </section>
                <section>
                  <IconClock />
                  <p>07:00 - 16:00</p>
                </section>
                <section>
                  <IconLocation />
                  <p onClick={() => window.open(linkLocal)}>{local}</p>
                </section>
              </article>

              <button
                onClick={() => window.open(linkWhatsapp)}
                className="clinit-start--btn clinit-start--banner__area-text-hours-btn"
              >
                <IconArrowRight className="clinit-start--banner__area-text-hours-icon" />{" "}
                Agendar
              </button>
            </div>
          </div>
        </div>

        {/* <div className="clinit-start--banner__area-image">
          <img src={imageWork} alt="Work" />
        </div> */}
      </div>
    </div>
  );
}

export default Banner;
