import React, { useEffect, useRef, useState } from "react";
import "../../styles/layouts/header.css";
import "../../styles/buttons/index.css";
import logo from "../../assets/imgs/logo/HORIZONTAL_VERDE CLARO.png";
import { useWindowScrollPositions } from "../../providers/useWindowScrollPositions";
import { Link, animateScroll as scroll } from "react-scroll";
import { useOnClickOutside } from "../../providers/functions";
import { nav } from "../../providers/infos";
import { useNavigate } from "react-router-dom";

function Header({ activeLink, setActiveLink, positionScrool }) {
  const [scrollTop, setScrollTop] = useState(false);
  const { scrollY } = useWindowScrollPositions();
  const history = useNavigate();

  //  const [active,setActive] = useState(false);

  //  const navToggle = () => {
  //   // active ===
  //   ? setActive''
  //   : setActive''

  //  }

  useEffect(() => {
    if (scrollY > 80) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  }, [scrollY]);

  useEffect(() => {
    const hash = window.location.hash;
    nav?.forEach((link) => {
      if (hash === link?.to) {
        setActiveLink(link?.id);
      }
    });
  }, [nav, setActiveLink]);

  const [modalMenu, setModalMenu] = useState(false);
  const modalMenuRef = useRef();
  useOnClickOutside(modalMenuRef, () => setModalMenu(false));

  return (
    <header
      className={
        scrollTop
          ? "clinit-start--header clinit-start--header--scroll-down "
          : "clinit-start--header"
      }
    >
      <img
        onClick={() => {
          setActiveLink(1);
          history(`/`);
          window.scrollTo({ top: 0 });
        }}
        style={{ cursor: "pointer" }}
        href="#banner"
        src={logo}
        alt="Start LOGO"
      />

      <nav className="clinit-start--header__nav">
        {nav?.map((link) => (
          <Link
            id={link?.id}
            to={link?.to}
            className={
              activeLink === link?.id ? "clinit-start--header__nav-active" : ""
            }
            onClick={() => {
              setActiveLink(link?.id);
              history(`/${link?.href ? link?.href : ""}`);
              window.scrollTo({ top: 0 });
            }}
            spy={true}
            smooth={true}
            offset={0}
            delay={0}
            duration={120}
          >
            {link?.name}

            {activeLink === link?.id ? <section></section> : ""}
          </Link>
        ))}
      </nav>

      <button
        style={{ width: 200 }}
        onClick={() => window.open("https://sistemaeso.com.br/system/login")}
        className="clinit-start--btn clinit-start--btn-solid"
      >
        Acesso ao Cliente
      </button>

      {/* MENU OPEN MENU */}
      <div
        onClick={() => setModalMenu(!modalMenu)}
        className="clinit-start--bt-menu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="18"
          viewBox="0 0 24 18"
          fill="none"
        >
          <path
            d="M4 5L20 5"
            stroke="#141B34"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={{
              transform: modalMenu ? "rotate(45deg)" : "rotate(0deg)",
              transformOrigin: "20% 38%",
            }}
          />
          <path
            d="M4 12L20 12"
            stroke="#141B34"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            style={{
              transform: modalMenu ? "rotate(-45deg)" : "rotate(0deg)",
              transformOrigin: "40% 65%",
            }}
          />
        </svg>
      </div>

      {/* MENU */}
      <div
        ref={modalMenuRef}
        className={
          modalMenu
            ? "clinit-start--menu"
            : "clinit-start--menu clinit-start--menu-hidden"
        }
      >
        <nav>
          {nav?.map((link) => (
            <Link
              id={link?.id}
              to={link?.to}
              className={
                activeLink === link?.id
                  ? "clinit-start--header__nav-active"
                  : ""
              }
              onClick={() => {
                setActiveLink(link?.id);
                setModalMenu(false);
                history(`/${link?.href ? link?.href : ""}`);
                window.scrollTo({ top: 0 });
              }}
              spy={true}
              smooth={true}
              offset={0}
              delay={0}
              duration={120}
            >
              {link?.name}

              {activeLink === link?.id ? <section></section> : ""}
            </Link>
          ))}
        </nav>

        <button
          onClick={() => window.open("https://sistemaeso.com.br/system/login")}
          className="clinit-start--btn clinit-start--btn-solid"
        >
          Acesso ao Cliente
        </button>
      </div>
    </header>
  );
}

export default Header;
