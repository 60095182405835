import React, { useMemo } from "react";
import "../../styles/views/about.css";
import imgAbout from "../../assets/imgs/about.png";
import { IconDiamond, IconEye, IconTarget } from "../../assets/icons/icons";
import Portifolio from "../portifolio";

function About({ activeLink, setActiveLink }) {
  const objectives = useMemo(
    () => [
      {
        icon: <IconTarget />,
        title: "Missão",
        description:
          "Ser referência em saúde ocupacional atendendo de forma humanizada, contribuindo em conformidade com nossos parceiros de forma ágil e objetiva, trazendo performance e soluções inteligentes",
      },
      {
        icon: <IconEye />,
        title: "Visão",
        description:
          "Trazer o que sua empresa necessita: Excelência, Desempenho, Regularização e Conformidade. Com preço justo.",
      },
      {
        icon: <IconDiamond />,
        title: "Valores",
        description:
          "Deus, Pontualidade, Agilidade, Inovação, Determinação, Empenho Alegria e Humanização.",
      },
    ],
    []
  );

  const percentages = useMemo(
    () => [
      {
        percente: 40,
        title: "Atendimentos",
      },
      {
        percente: 30,
        title: "Clientes",
      },
      {
        percente: 65,
        title: "Empresas  Ativas",
      },
      {
        percente: 20,
        title: "Descontos",
      },
    ],
    []
  );

  return (
    <div className="clinit-start--about" id="about">
      <section className="clinit-start--about__area-objective">
        {objectives?.map((obj) => (
          <div>
            <section></section>
            {obj?.icon}
            <h2>{obj?.title}</h2>
            <p>{obj?.description}</p>
          </div>
        ))}
      </section>
      <Portifolio activeLink={activeLink} setActiveLink={setActiveLink} />
      <section className="clinit-start--about__area-text">
        <img src={imgAbout} alt={"Sobre nós imagem"} />
        <div>
          <h2 className="clinit-start--title">
            Quem <b className="clinit-start--about__area-text-b">Somos</b>
          </h2>
          <p>
            A <b className="font-ideal">IDEAL SST</b> é uma clínica
            especializada em Saúde e Segurança do Trabalho, dedicada à
            implantação e gerenciamento de programas ocupacionais para nossos
            clientes. Nossa missão é promover a saúde e bem-estar dos
            trabalhadores.
            <br />
            <br />
            Contamos com uma estrutura moderna e confortável, proporcionando
            agilidade, conforto e eficiência no atendimento. Na{" "}
            <b className="font-ideal">IDEAL SST</b>, oferecemos uma ampla gama
            de serviços, incluindo exames clínicos (ASO), exames laboratoriais,
            audiometria, eletrocardiograma, eletroencefalograma, espirometria e
            acuidade visual.
            <br />
            <br />
            Nossa equipe altamente qualificada de profissionais do{" "}
            <b className="font-ideal">SESMT</b> está pronta para oferecer o
            melhor atendimento para sua empresa.
          </p>

          <section className="clinit-start--about__area-text-percentages">
            {percentages?.map((per) => (
              <div>
                <h2>+{per?.percente}%</h2>
                <p>{per?.title}</p>
              </div>
            ))}
          </section>
        </div>
      </section>
    </div>
  );
}

export default About;
