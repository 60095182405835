import React from "react";
import { IconArrowDown } from "../../assets/icons/icons";
import "../../styles/views/services.css";
import { Link } from "react-scroll";

function PreviewServices({ services, setActiveService }) {
  return (
    <div className="clinit-start--preview-services">
      {services?.map((serv) => (
        <div className="clinit-start--preview-services__item">
          {serv?.icon}
          <h2>{serv?.type}</h2>
          <p>{serv?.desc}</p>

          <Link
            id={serv?.id}
            to={"services"}
            onClick={() => setActiveService(serv?.id)}
            className="clinit-start--btn clinit-start--preview-services__btn"
            spy={true}
            smooth={true}
            offset={0}
            duration={200}
          >
            <IconArrowDown className="clinit-start--preview-services__icon" />{" "}
            Saiba mais
          </Link>
        </div>
      ))}
    </div>
  );
}

export default PreviewServices;
