import React, { useEffect, useState } from "react";
import pdfFile from "./portifolioidealsst.pdf";
import Header from "../../layouts/Header";
import { IconDownload } from "../../assets/icons/icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

import img1 from "../../assets/imgs/portifolio/1.png";
import img2 from "../../assets/imgs/portifolio/2.png";
import img3 from "../../assets/imgs/portifolio/3.png";
import img4 from "../../assets/imgs/portifolio/4.png";
import img5 from "../../assets/imgs/portifolio/5.png";
import img6 from "../../assets/imgs/portifolio/6.png";
import img7 from "../../assets/imgs/portifolio/7.png";
import img8 from "../../assets/imgs/portifolio/8.png";
import img9 from "../../assets/imgs/portifolio/9.png";
import img10 from "../../assets/imgs/portifolio/10.png";
import img11 from "../../assets/imgs/portifolio/11.png";
import img12 from "../../assets/imgs/portifolio/12.png";
import img13 from "../../assets/imgs/portifolio/13.png";
import img14 from "../../assets/imgs/portifolio/14.png";
import img15 from "../../assets/imgs/portifolio/15.png";
import img16 from "../../assets/imgs/portifolio/16.png";
import img17 from "../../assets/imgs/portifolio/17.png";
import img18 from "../../assets/imgs/portifolio/18.png";
import img19 from "../../assets/imgs/portifolio/19.png";

function Portifolio({ activeLink, setActiveLink }) {
  const getWidth = () => {
    if (window.matchMedia("(max-device-width: 960px)").matches) {
      const winW =
        window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      return winW;
    } else {
      const winW = window.screen.width;
      return winW;
    }
  };

  return (
    <>
      {/* <Header activeLink={activeLink} setActiveLink={setActiveLink} /> */}
      <div className="clinit-start--portifolio" id="portfolio">
        <button
          onClick={() => {
            const data = pdfFile;
            const link = document.createElement("a");

            if (typeof link.download === "string") {
              link.href = data;
              link.download = `portifolio_idealsst.pdf`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              window.open(data);
            }
          }}
          className="clinit-start--btn clinit-start--banner__area-text-hours-btn"
        >
          <IconDownload className="clinit-start--banner__area-text-hours-icon" />{" "}
          Baixar Portifólio
        </button>

        {/* <embed title="Nosso Portifólio" src={pdfFile} /> */}
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={getWidth() <= 600 ? 1 : getWidth() <= 1255 ? 2 : 3}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {[
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8,
            img9,
            img10,
            img11,
            img12,
            img13,
            img14,
            img15,
            img16,
            img17,
            img18,
            img19,
          ]?.map((img, i) => (
            <SwiperSlide>
              <img src={img} alt={`Portifólio img ${i}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Portifolio;
